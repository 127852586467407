import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Layout from "../components/Layout";
import SectionThree from "../components/SectionThree";
import SectionTwo from "../components/SectionTwo";
import SectionOne from "../components/SectionOne";
import { Menu } from "../components/Menu/Menu";
import { Navbar } from "../components/Navbar";

const zeroImage = `!!mainImage?.childImageSharp
  ? mainImage?.childImageSharp.fluid.src
  : mainImage`

const ZeroSection = styled.div`
  height: 100vh;
  position: relative;
  overflow : hidden;
`;

const ZoomAnimation = keyframes`
  0% {transform: scale(1.1, 1.1)}
  50% {transform: scale(1.3, 1.3)}
  100% {transform: scale(1.1, 1.1)}
`;

const AnimationBox = styled.div`
  // overflow-y: hidden;
  // margin-top: -20px;
  // height: 100vh;
  // background-size: 110% 110%;
  // background-position: center center;
   animation: ${ZoomAnimation} 60s infinite alternate;

  img {
    //width: 100%;
  }
`;

const DescriptionBar = styled.div`
  height: 120px;
  width: 100%;
  opacity: 0.5;
  background-color: #1d1d1b;
  position: absolute;
  bottom: 0;
  z-index: 10;
`;


const DescriptionText = styled.div`
  height: 70px;
  position: absolute;
  bottom: 0;
  padding: 25px 70px;
  z-index: 12;

  h3 {
    color: white;
  }

  h4 {
    color: white;
  }

  @media (max-width: 700px) {
    padding: 25px 30px;
    
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 14px;
    }
  }
`

const IndexPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        PL_HeaderOne,
        ENG_HeaderOne,
        PL_TextOne,
        ENG_TextOne,
        PL_HeaderTwo,
        ENG_HeaderTwo,
        PL_TextTwo,
        ENG_TextTwo,
        PL_HeaderThree,
        ENG_HeaderThree,
        PL_TextThree,
        ENG_TextThree,
        imageOne,
        imageTwo,
        imageThree,
        mainTextTwo,
        mainTextOne,
        mainImage,
      },
    },
  } = data || {};

  const texts = {
    PL_HeaderOne,
    ENG_HeaderOne,
    PL_TextOne,
    ENG_TextOne,
    PL_HeaderTwo,
    ENG_HeaderTwo,
    PL_TextTwo,
    ENG_TextTwo,
    PL_HeaderThree,
    ENG_HeaderThree,
    PL_TextThree,
    ENG_TextThree,
  };

  const [language, setLanguage] = useState("pl");

  useEffect(() => {
    setLanguage(localStorage.getItem("language") || "pl");
  }, []);

  const handleLanguageClick = () => {
    if (language === "pl") {
      setLanguage("eng");
      localStorage.setItem("language", "eng");
    } else {
      setLanguage("pl");
      localStorage.setItem("language", "pl");
    }
  };

  const translate = (key) => {
    return `${language?.toUpperCase()}_${key}`;
  };

  return (
    <Layout>
        <Menu
          language={language === "pl" ? "eng" : "pl"}
          handleLanguageClick={handleLanguageClick}
        />
        <Navbar />
        <ZeroSection>
          <AnimationBox>
          <img src={!!mainImage?.childImageSharp
              ? mainImage?.childImageSharp.fluid.src
              : mainImage} />
          </AnimationBox>
          <DescriptionBar/>
          <DescriptionText>
            <h3>{mainTextOne}</h3>
            <h4>{mainTextTwo}</h4>
          </DescriptionText>
        </ZeroSection>

        <SectionOne
          title={texts[translate("HeaderOne")]}
          content={texts[translate("TextOne")]}
          img={
            !!imageOne?.childImageSharp
              ? imageOne?.childImageSharp.fluid.src
              : imageOne
          }
        />

        <SectionTwo
          title={texts[translate("HeaderTwo")]}
          content={texts[translate("TextTwo")]}
          img={
            !!imageTwo?.childImageSharp
              ? imageTwo?.childImageSharp.fluid.src
              : imageTwo
          }
        />

        <SectionThree
          title={texts[translate("HeaderThree")]}
          content={texts[translate("TextThree")]}
          img={
            !!imageThree?.childImageSharp
              ? imageThree?.childImageSharp.fluid.src
              : imageThree
          }
        />
    </Layout>
  );
};

export default IndexPage;

export const indexPageQuery = graphql`
  query indexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        PL_HeaderOne
        ENG_HeaderOne
        PL_TextOne
        ENG_TextOne
        PL_HeaderTwo
        ENG_HeaderTwo
        PL_TextTwo
        ENG_TextTwo
        PL_HeaderThree
        ENG_HeaderThree
        PL_TextThree
        ENG_TextThree
        mainTextTwo
        mainTextOne
        mainImage {
          childImageSharp {
            fluid(maxWidth: 2440, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageOne {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageTwo {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageThree {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
