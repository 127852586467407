import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import style from "./markdown-styles.module.css";

const StyledWrapper = styled.div`
  max-width: 1280px;
  margin: 200px auto;
  padding: 0 70px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    margin: 100px auto 50px;
    padding: 0 30px;
  }
`

const TextBox = styled.div`
  align-self: end;
  p {
    margin-top: 20px;
  }
`;

const ImageBox = styled.div`
  max-width: 100%;
  width: auto;
  img {
    max-width: 100%;
    width: auto;
  }
`;

export const HTMLContent = ({ content, className }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
);

const SectionOne = ({ title, content, img }) => {
  return (
    <StyledWrapper>
      <TextBox>
        <h3>{title}</h3>
        <ReactMarkdown className={style.reactMarkDown} children={content} />
      </TextBox>
      <ImageBox>
        <img alt="team" src={img} />
      </ImageBox>
    </StyledWrapper>
  );
};

export default SectionOne;
